
import React from "react"
import { Link as ChakraLink } from "@chakra-ui/core"
import { Link as GatsbyLink } from "gatsby"

const Link = ({ children, to, ...restProps }) => {
  const internal = /^\/(?!\/)/.test(to)
  return internal
    ? <ChakraLink as={GatsbyLink} to={to} {...restProps}>{children}</ChakraLink>
    : <ChakraLink href={to} isExternal {...restProps}>{children}</ChakraLink>
}

export default Link
